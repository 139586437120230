export const breakpointNumbers = [480, 824, 1025]
export const fullSize = 1450
export const breakpoints = breakpointNumbers.map((number) => `${number}px`)

// apply breakpoints function
export function ab(breakpointValues) {
  let breakpoints = []
  breakpoints._ = 0
  breakpoints.sm = 1
  breakpoints.md = 2
  breakpoints.lg = 3

  let result = []

  for (var i = 0; i < Object.keys(breakpointValues).length; i++) {
    result[breakpoints[Object.keys(breakpointValues)[i]]] =
      breakpointValues[Object.keys(breakpointValues)[i]]
  }
  return result
}
export const navigationMobileHeight = "60px"
export const navigationDesktopWidth = "90px"
export const navigationSubmenuDesktopWidth = "300px"
export const headerMobileHeight = "60px"

export const groupColor = "#004787"
const groupColorAccent = "#7fa3c3"
const pharmaColor = "#4e7ea8"
const pharmaColorAccent = "#9cb6cd"
const foodColor = "#47a8bd"
const foodColorAccent = "#99ced9"
const environmentColor = "#27738a"
const environmentColorAccent = "#90a9b8"
const cosmeticsColor = "#6C76B2"
const veterinaryColor = "#76AEAF"
const contactColor = "#606085"
const careerColor = "#FFBE0B"
const gutesiegelColor = "#E5EDF2"
const gutesiegelColorAccent = "#0E0E0E0"
const wasserColor = "#287BA4"
const constructionColor = "#599297"
const medicalColor = "#4B93B3"
const lifeScienceColor = "#6c6688"
const agroChemicalColor = "#86A067"
const sustainabilityServicesColor = "#077218"

export const colors = {
  text: "#5a5d5f",
  background: "#fff",
  secondaryBackground: "#efefef",
  primary: groupColor,
  accent: groupColorAccent,
  navigationText: "#fff",
  modes: {
    group: {
      text: "#5a5d5f",
      background: "#fff",
      primary: groupColor,
      accent: groupColorAccent,
    },
    pharma: {
      text: "#5a5d5f",
      background: "#fff",
      primary: pharmaColor,
      accent: pharmaColorAccent,
    },
    food: {
      text: "#5a5d5f",
      background: "#fff",
      primary: foodColor,
      accent: foodColorAccent,
    },
    environment: {
      text: "#5a5d5f",
      background: "#fff",
      primary: environmentColor,
      accent: environmentColorAccent,
    },
    cosmetics: {
      text: "#5a5d5f",
      background: "#fff",
      primary: cosmeticsColor,
      accent: cosmeticsColor,
    },
    veterinary: {
      text: "#5a5d5f",
      background: "#fff",
      primary: veterinaryColor,
      accent: veterinaryColor,
    },
    sustainabilityServices: {
      text: "#5a5d5f",
      background: "#fff",
      primary: sustainabilityServicesColor,
      accent: sustainabilityServicesColor,
    },
    contact: {
      text: "#5a5d5f",
      background: "#fff",
      primary: contactColor,
      accent: contactColor,
    },
    career: {
      text: "#5a5d5f",
      background: "#fff",
      primary: careerColor,
    },
    gutesiegel: {
      text: "#646464",
      navigationBackground: "#F0F5F8",
    },
    department: {
      text: "#5a5d5f",
      background: "#fff",
      primary: groupColor,
      accent: groupColorAccent,
    },
    wasser: {
      text: "#5a5d5f",
      background: "#fff",
      primary: wasserColor,
    },
    construction: {
      text: "#5a5d5f",
      background: "#fff",
      primary: constructionColor,
    },
    medical: {
      text: "#5a5d5f",
      background: "#fff",
      primary: medicalColor,
    },
    lifeScience: {
      text: "#5a5d5f",
      background: "#fff",
      primary: lifeScienceColor,
    },
    agroChemical: {
      text: "#5a5d5f",
      background: "#fff",
      primary: agroChemicalColor,
    },
    none: {
      text: "#5a5d5f",
      background: "#fff",
      primary: gutesiegelColor,
      accent: gutesiegelColorAccent,
      navigationBackground: "#F0F5F8",
      none: {
        text: "#5a5d5f",
        background: "#fff",
        primary: "#5a5d5f",
      },
    },
  },
  navigation: {
    background: "#efefef",
    group: groupColor,
    department: groupColor,
    pharma: pharmaColor,
    food: foodColor,
    environment: environmentColor,
    career: careerColor,
    contact: contactColor,
    construction: constructionColor,
    cosmetics: cosmeticsColor,
    veterinary: veterinaryColor,
    wasser: wasserColor,
    medical: medicalColor,
    lifeScience: lifeScienceColor,
    agroChemical: agroChemicalColor,
    sustainabilityServices: sustainabilityServicesColor,
    default: "#5a5d5f",
    icon: "#fff",
  },
}

export const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  lineHeights: {
    body: 1.7,
    heading: 1.15,
  },
  fonts: {
    body: "Open Sans, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  initialColorMode: "default",
  colors: colors,
  breakpoints: breakpoints,
  sizes: {
    default: "100%",
    max: fullSize,
    navigationMobileHeight: navigationMobileHeight,
    navigationDesktopWidth: navigationDesktopWidth,
    navigationSubmenuDesktopWidth: navigationSubmenuDesktopWidth,
    headerMobileHeight: headerMobileHeight,
  },
  logo: {
    container: {
      position: ab({ _: "relative", md: "fixed" }),
      top: ab({ md: 32 }),
      ml: ab({ md: 32 }),
      background: ab({ _: "none", md: "#fff" }),
      height: ab({ _: 30, md: "auto" }),
      width: ab({ _: "100%", md: "auto" }),
    },
    img: {
      marginTop: ab({ _: -15, md: 0 }),
      display: "inline-block",
    },
  },
  spacingContainer: {
    my: ab({ _: 5, md: 5 }),
    px: ab({ _: 3, md: 4 }),
  },
  spacingContainerFullsize: {
    my: ab({ _: 5, md: 6 }),
    "& + div": {
      mt: 0,
      pt: 0,
    },
  },
  innerSpacingContainer: {
    py: ab({ _: 3, md: 5 }),
    px: ab({ _: 3, md: 4 }),
  },
  alternatingSpacingContainer: {
    py: ab({ _: 3, md: 5 }),
    px: ab({ _: 3, md: 4 }),
    "& + div": {
      mt: 0,
      pt: 0,
    },
  },
  header: {
    navigation: {
      float: "right",
      display: ab({ _: "flex", md: "none" }),
    },
    languageSwitcher: {
      fontSize: "15px",
      padding: "4px",
      order: 2,
      height: "24px",
      zIndex: "10000",
      "& ul": {
        m: 0,
        p: 0,
        overflow: "hidden",
        width: "54px",
        position: "relative",
        backgroundColor: "#efefef",
        maxHeight: "27px",
        transition: "all .3s",
        "&:after": {
          transition: "all .3s",
          content: "''",
          position: "absolute",
          right: "10px",
          top: "8px",
          fontSize: "15px",
          transform: "rotate(-45deg)",
          border: "solid 1px #5a5d5f",
          height: "7px",
          width: "7px",
          borderColor: " transparent transparent #5a5d5f #5a5d5f",
        },

        "&.active": {
          boxShadow: "1px 1px 2px #ddd, -1px -1px 3px #ddd",
          transition: "all .3s",
          maxHeight: "85px",
          "&:after": {
            transition: "all .2s",
            borderColor: "#5a5d5f #5a5d5f transparent transparent",
            top: "12px",
          },
          "li:nth-child(n+2)": {
            maxHeight: "28px",
            transition: "all .3s",
            zIndex: 10,
          },
          "li:first-child": {
            maxHeight: "0px",
            opacity: 0,
          },
        },
      },
      "& li": {
        listStyle: "none",
        pl: 2,
        position: "relative",
      },
      "li:nth-child(n+2)": {
        // display: "none",
        maxHeight: "0px",
        transition: "all .2s",
      },
    },
    searchTrigger: {
      order: 1,
    },
    spacer: {
      width: "100%",
      height: ab({ _: "headerMobileHeight", md: 0 }),
    },
    languageSwitcherList: {
      listStyle: "none",
      "& li": {
        listStyle: "none",
      },
    },
  },
  footer: {},

  navigation: {
    icon: {
      fill: colors.navigation.default,
    },
    nav: {
      display: ab({ md: "flex" }),
      flexDirection: "column",
      position: "fixed",
      top: ab({ md: 0 }),
      right: ab({ _: 0, md: "auto" }),
      bottom: 0,
      left: ab({ _: 0, md: "auto" }),
      zIndex: 101,
      "& ul": {
        listStyle: "none",
      },
    },
    unorderedList: {
      display: "flex",
      flexDirection: ab({ _: "row", md: "column" }),
      height: ab({ _: "60px", md: "calc(100% - 140px)" }),
      width: ab({ md: "90px" }),
      margin: 0,
      padding: 0,
      justifyContent: "center",
    },
    iconContainer: {
      width: ab({ _: 30, md: 35 }),
      height: ab({ _: 30, md: 35 }),
      margin: "0 auto",
      paddingBottom: "5px",
      marginBottom: ab({ md: "5px" }),
    },
    mainItem: {
      flex: 1,
      textAlign: "center",
      py: 2,
      px: 1,
      "&:hover": {
        backgroundColor: "tranparent",
      },
      display: ab({ md: "flex" }),
      flexDirection: ab({ md: "column" }),
      justifyContent: ab({ md: "center" }),
    },
    mainItemBtn: {
      cursor: "pointer",
    },
    mainContainer: {
      backgroundColor: colors.navigation.background,
      bottom: 0,
      top: ab({ _: "auto", md: 0 }),
      left: ab({ _: 0, md: "auto" }),
      right: ab({ _: 0, md: "auto" }),
      display: "flex",
      flexDirection: ab({ _: "row", md: "column" }),
      fontSize: ab({ _: "9px", md: "11px" }),
      width: ab({ md: navigationDesktopWidth }),
      height: ab({ _: navigationMobileHeight, md: "auto" }),
      position: "absolute",
    },
    layer: {
      height: ab({ _: "calc(100% - 60px)", md: "auto" }),
    },
    container: {
      position: "fixed",
      height: ab({ _: navigationMobileHeight, md: "auto" }),
      zIndex: 101,
      bottom: 0,
      top: ab({ _: "auto", md: 0 }),
      left: ab({ _: 0, md: "auto" }),
      right: ab({ _: 0, md: "auto" }),
      //transform: "translateX(0)",
      transition: "all 400ms ease-in-out",
      width: ab({ md: navigationDesktopWidth }),
      overflow: ab({ _: "auto", md: "hidden" }),
    },
    containerOpen: {
      transform: ab({
        md: "translateX(-" + navigationSubmenuDesktopWidth + ")",
      }),
      height: ab({ _: `calc(100vh - ${headerMobileHeight})`, md: "auto" }),
      width: ab({
        md: `calc(${navigationDesktopWidth} + ${navigationSubmenuDesktopWidth})`,
      }),
      transition: "all 300ms ease-in-out",
      position: "fixed",
      zIndex: 101,
      bottom: 0,
      top: ab({ _: "auto", md: 0 }),
      left: ab({ _: 0, md: "auto" }),
      right: ab({ _: 0, md: "auto" }),
      overflow: ab({ _: "auto", md: "hidden" }),
    },
    languageSwitcher: {
      display: ab({ _: "none", md: "flex" }),
      fontSize: ab({ md: "11px" }),
      paddingBottom: "30px",
      flex: 2,
      justifyContent: "flex-end",
      flexDirection: "column",
      alignItems: "center",
      ul: {
        p: 0,
        m: 0,
      },
      li: {
        listStyle: "none",
        display: "inline",
      },
      "li:first-child": {
        display: "none ",
      },
      "li:not(:last-child)": {
        "&:after": {
          content: "'|'",
          fontSize: "8px",
          position: "relative",
          top: "-2px",
        },
      },
      button: {
        px: "4px",
      },
    },
    searchTrigger: {
      paddingTop: "47px",
      flex: 2,
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "center",
    },
    spacer: {
      width: "100%",
      height: navigationMobileHeight,
    },
    currentPage: {
      borderBottom: "2px solid",
      display: "inline-block",
    },
    hover: {
      position: "relative",
      "&::after": {
        content: "''",
        width: 0,
        height: 2,
        backgroundColor: "#fff",
        display: "block",
        transition: "200ms ease-out",
        position: "absolute",
        bottom: "-2px",
      },
      "&:hover": {
        "&::after": {
          width: "100%",
        },
      },
    },
    linkbtmbrd: {
      color: "#fff",
      textDecoration: "none",
      display: "inline-block",
      "&:after": {
        content: "''",
        width: 0,
        height: 2,
        backgroundColor: "#fff",
        display: "block",
        transition: "200ms ease-out",
      },
      "&:hover": {
        "&:after": {
          width: "100%",
        },
      },
    },
    submenu: {
      position: "absolute",
      left: ab({ _: 0, md: "100%" }),
      top: ab({ _: "auto", md: 0 }),
      bottom: ab({ _: navigationMobileHeight, md: 0 }),
      height: ab({
        _:
          "calc(100vh - " +
          navigationMobileHeight +
          " - " +
          headerMobileHeight +
          ")",
        md: "auto",
      }),
      width: ab({ _: "100%", md: navigationSubmenuDesktopWidth }),
      overflowX: "scroll",
    },
  },

  quote: {
    author: {
      paddingTop: 2,
      fontSize: 2,
    },
  },

  checkbox: {
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "80%",
    cursor: "pointer",
    zIndex: 5,
    "&:checked ~ span": {
      display: "block",
    },
    label: {
      paddingLeft: 40,
      lineHeight: "40px",
    },
    fieldGroupLabel: {
      paddingLeft: 40,
      fontSize: 24,
      lineHeight: "40px",
    },
  },

  styles: {
    h1: {
      fontSize: ab({ _: 4, md: "38px" }),
      fontWeight: 1,
      lineHeight: "heading",
      px: ab({ _: 3, md: 4 }),
    },
    h2: {
      fontSize: 4,
      fontWeight: 1,
      lineHeight: "heading",
    },
    Header: {
      maxWidth: "max",
      width: "default",
      margin: "auto",
      position: ab({ _: "fixed", md: "relative" }),
      backgroundColor: ab({ _: "navigation.background", md: "none" }),
      padding: ab({ _: 15, md: 0 }),
      height: ab({ _: "headerMobileHeight", md: "auto" }),
      zIndex: 99,
    },
    Container: {
      maxWidth: "max",
      position: "relative",
      overflowX: "hidden",
      hyphens: "auto",
      wordWrap: "break-word",
      p: 0,
      "& p a": {
        color: "primary",
      },
      "& ul li a": {
        color: "primary",
      },
    },
    Main: {
      maxWidth: "max",
      width: "default",
      margin: "auto",
      fontFamily: "body",
      fontWeight: 1,
      paddingRight: ab({ md: navigationDesktopWidth }),
      backgroundColor: "background",
    },
    Layout: {
      background: ab({ _: "#fff", md: "#fff" }),
      color: "text",
      margin: "auto",
    },
    footerlink: {
      textDecoration: "none",
      color: "text",
      py: 2,
      px: ab({ _: 1, lg: 4 }),
      textAlign: "center",
      display: ab({ _: "block", md: "inline" }),
    },
    linkbtmbrd: {
      color: "text",
      textDecoration: "none",
      "&:after": {
        content: "''",
        width: 0,
        height: 2,
        backgroundColor: "text",
        display: "block",
        transition: "200ms ease-out",
      },
      "&:hover": {
        "&:after": {
          width: "100%",
        },
      },
    },
    btn: {
      backgroundColor: "transparent",
      borderRadius: 25,
      textDecoration: "none",
      cursor: "pointer",
    },
    ghostButton: {
      backgroundColor: "transparent",
      textDecoration: "none",
      cursor: "pointer",
      transition: "all .5s ease",
      borderStyle: "solid",
      borderColor: "text",
      borderWidth: "1px",
      display: "inline-block",
      borderRadius: "50px",
      textAlign: "center",
      lineHeight: "1.1",
      "&:hover": {
        bg: "text",
        color: "#fff",
        "& a": {
          color: "#fff",
        },
      },
      "& a": {
        color: "text",
        backgroundColor: "transparent",
        textDecoration: "none",
      },
    },
    threeColumnBg: {
      bg: "secondaryBackground",
      px: 3,
      pb: 2,
      "& ul": {
        paddingLeft: "16px",
      },
      "& li": {
        fontWeight: "bold",
        pb: 2,
      },
      "& li  li": {
        fontWeight: "normal",
      },
    },
    success: {
      background: environmentColor,
      color: "background",
    },
    failed: {
      background: "#ee3355",
      color: "background",
    },
    swing: {
      "@keyframes swing": {
        "15%": {
          transform: "translateX(5px)",
        },
        "30%": {
          transform: "translateX(-5px)",
        },
        "50%": {
          transform: "translateX(3px)",
        },
        "65%": {
          transform: "translateX(-3px)",
        },
        "80%": {
          transform: "translateX(2px)",
        },
        "100%": {
          transform: "translateX(0)",
        },
      },
    },
    fadeInAni: {
      "@keyframes fadeInAni": {
        "0%": {
          opacity: 0,
          display: "none",
          maxWidth: 0,
        },
        "1%": {
          display: "block",
        },
        "30%": {
          opacity: 0.5,
        },
        "100%": {
          opacity: 1,
          maxWidth: "100%",
        },
      },
    },
    fadeOutAni: {
      "@keyframes fadeOutAni": {
        "0%": {
          opacity: 1,
          display: "block",
          maxWidth: "100%",
        },

        "30%": {
          opacity: 0.5,
        },
        "99%": {
          display: "block",
        },
        "100%": {
          opacity: 0,
          display: "none",
          maxWidth: "00%",
        },
      },
    },
  },
  bigblogteaser: {
    h2: {
      fontWeight: 1,
      my: 5,
    },
  },
  smallblogteaser: {
    date: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10,
      bg: "primary",
      color: "background",
      py: 2,
      px: ab({ _: 2, md: 3 }),
      transform: "translateY(-50%)",
      fontSize: 1,
      letterSpacing: 1,
      ml: ab({ _: 0, md: -3 }),
    },
  },
  form: {
    formField: {
      backgroundColor: "secondaryBackground",
      borderRadius: "20px",
      py: 3,
      px: 1,
      textTranform: "capitalize",
      display: "flex",
      position: "relative",
      "& label": {
        paddingLeft: 2,
      },
    },
    input: {
      backgroundColor: "transparent",
      border: 0,
      flexGrow: 1,
      px: 2,
      color: "text",
      "&:focus": {
        outline: "none",
      },
      "::-webkit-input-placeholder": {
        color: "#a2a2a2",
        opacity: "1",
      },
      ":-ms-input-placeholder": {
        color: "#a2a2a2",
        opacity: "1",
      },
      "::placeholder": {
        color: "#a2a2a2",
        opacity: "1",
      },
    },
    mark: {
      backgroundColor: "secondaryBackground",
      position: "absolute",
      height: "25px",
      width: "25px",
      display: "block",
      left: "0px",
      top: "30px",
    },
    checkmark: {
      height: "15px",
      width: "8px",
      marginRight: "15px",
      display: "block",
      left: "8px",
      top: "33px",
      border: "solid",
      borderColor: "text",
      borderWidth: "0 3px 3px 0",
      transform: "rotate(45deg)",
      position: "absolute",
    },
    radio: {
      backgroundColor: "text",
      height: "13px",
      width: "13px",
      position: "absolute",
      top: "22px",
      left: "6px",
      borderRadius: "50%",
    },
    arrow: {
      display: "inline-block",
      position: "absolute",
      width: "12px",
      height: "12px",
      background: "transparent",
      textIndent: "-9999px",
      borderTop: "2px solid",
      borderLeft: "2px solid",
      borderColor: "text",
      textDecoration: "none",
      color: "transparent",
      transform: "rotate(135deg)",
      zIndex: 3,
      marginTop: 1,
      right: 4,
    },
  },
  jobsTable: {
    width: "100%",
    borderCollapse: "collapse",
    "td, th": {
      py: "18px",
    },
    th: {
      textAlign: "left",
      color: "primary",
    },
    tr: {
      borderBottom: "solid 1px #000",
      "&:last-child": {
        border: 0,
      },
      button: {
        bg: "primary",
        color: "#fff",
        border: 0,
      },
    },
  },
  gutesiegel: {
    header: {
      "& .search-trigger-container": {
        display: "none",
      },
    },
    navigation: {
      "& .navigation-container ": {
        bg: "#F0F5F8",
        transform: "translateX(0)",
        "& > div": {
          justifyContent: "center",
        },
        "&  a": {
          color: groupColor,
        },
      },
      "& .navigation-overlay": {
        display: "none",
      },
      "& .main-navigation-item": {
        maxHeight: "100px",
      },
      "& .search-trigger-container": {
        display: "none",
      },
      "& .language-switcher": {
        display: "none",
      },
    },
  },
  specialHeader: {
    paddingLeft: ab({ _: 3, md: "300px" }),
    my: ab({ _: 3, md: "55px" }),
    fontSize: 1,
  },
}

export default {
  breakpoints,
  theme,
}
