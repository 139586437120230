module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-gba/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://silly-brigadeiros-d0eaca.netlify.app","siteId":"7c0abd0a-995d-4d2e-bb6d-489a68823ce8","branch":"master","algoliaSearchApiKey":"sddsfsdf2342","algoliaApplicationId":"sdfsdfsfw32423","context":"production"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
