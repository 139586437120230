import { merge } from "theme-ui"
import { DefaultTheme, ab, colors } from "gatsby-theme-gba"

const theme = merge(DefaultTheme.theme, {
  colors: {
    modes: {
      group: {},
      guc: {
        text: "#000",
        navigationBackground: "transparent",
      },
    },
    navigation: {
      husAbout: "#136baf",
      husNews: "#c64f1a",
      husJob: "#ffbe0b",
      husService: "#074785",
      husHome: "#074785",
      husContact: "#074785",
      husWater: "#136baf",
    },
  },

  hus: {
    header: {
      backgroundColor: "#fff !important",
    },
  },

  logo: {
    container: {
      //padding: "20px",
      "div:nth-child(2)": {
        display: "none !important",
      },
      ".logo-inner-link": {
        padding: ab({ _: "0 5px", md: "10px " }),
      },
    },
  },

  layout: {
    main: {
      "> div.image-with-teaser-box": {
        mt: "0",
        pt: "64px !important",
      },
    },
  },

  navigation: {
    searchTrigger: {
      display: "none !important",
      div: {
        display: "none !important",
      },
    },
    languageSwitcher: {
      display: "none !important",
    },
  },
  guc: {
    mainNavigationItem: {
      color: "text",
    },
  },

  pageTeaserText: {
    display: "flex",
    flexDirection: "column",
  },

  pageTeaser: {
    popup: {
      position: "relative",
      ".popup-text": {
        backgroundColor: " #004787 !important",
      },
      ul: {
        padding: ab({ _: "0px", md: "20px" }),
      },
      li: {
        listStyleType: "none",
        textAlign: "left",
        fontSize: ab({ _: "12px", md: "14px" }),
      },
      a: {
        color: "#47a8bd",
      },
    },
    h3: {
      lineHeight: "1.3",
      fontWeight: "normal",
      mt: "5px",
    },
    child: {
      mb: "auto",
    },
  },
  ctaArea: {
    a: {
      color: "#fff",
    },
  },
  specialHeader: {
    paddingLeft: ab({ _: 3, md: "400px" }),
    my: ab({ _: 4, md: "75px" }),
  },
})

export default theme
