/** @jsx jsx */
import { Container, BaseStyles, jsx } from "theme-ui"
import PropTypes from "prop-types"
import Markdown from "react-markdown"

import EditorContext from "../context/editor-context"
import ModeContext from "../context/mode-context"
import { NavigationProvider } from "../context/navigation-context"

import SiteMetadata from "./site-metadata"
import SwitchColorMode from "./switch-color-mode"
import Header from "./header"
import NavigationContainer from "./navigation/"
import StickyContactButton from "./sticky-contact-button"
import SearchModalConsumer from "./search-modal-consumer"
import Footer from "./footer"
import { useAlgolia } from "../hooks/use-algolia"

import "../normalize.css"
import "../ie.scss"
import "../cookiebot.css"
import "../faq-legionellen.scss"

const Layout = ({
  children,
  logos,
  favicon,
  appleFavicon,
  theme,
  currentMode,
  navigation,
  currentLanguage,
  allLanguage,
  currentURL,
  defaultTitle,
  title,
  contactDetails,
  canonicalLink,
  imageURL,
  description,
  noAnalytics,
  isEditor,
  location,
  enableCookieBot,
}) => {
  const { algoliaApplicationId, algoliaSearchApiKey, branch, siteId } =
    useAlgolia()

  const algoliaIndex = process.env.GATSBY_ALGOLIA_INDEX
    ? process.env.GATSBY_ALGOLIA_INDEX
    : `GBA-Group`
  //const algoliaIndex = `netlify_${siteId}_${branch}_all`

  return (
    <BaseStyles data-cy-mode={currentMode}>
      <SiteMetadata
        title={title}
        defaultTitle={defaultTitle}
        imageURL={imageURL}
        language={currentLanguage}
        canonicalLink={canonicalLink}
        description={description}
        favicon={favicon}
        appleFavicon={appleFavicon}
        location={location}
        allLanguage={allLanguage}
        mode={currentMode}
        noAnalytics={noAnalytics}
        enableCookieBot={enableCookieBot}
        theme={theme}
      />
      <SwitchColorMode mode={currentMode} />
      <Header
        logos={logos}
        currentMode={currentMode}
        currentTheme={theme}
        currentLanguage={currentLanguage}
        allLanguage={allLanguage}
        currentURL={currentURL}
        locationText={
          navigation &&
          navigation[currentLanguage] &&
          navigation[currentLanguage].LocationText
        }
        locationLink={
          navigation &&
          navigation[currentLanguage] &&
          navigation[currentLanguage].LocationLink
        }
        navigation={process.env.GATSBY_HEADER_MENU ? navigation : ""}
      />
      <div sx={{ variant: "header.spacer" }}> </div>
      <div
        sx={{
          position: "relative",
          maxWidth: "max",
          margin: "auto",
        }}
      >
        <NavigationProvider>
          <NavigationContainer
            theme={theme}
            items={navigation}
            language={currentLanguage}
            languages={allLanguage}
            currentURL={currentURL}
            location={location}
          />
        </NavigationProvider>
        <main data-mode={currentMode} sx={{ variant: "layout.main" }}>
          <EditorContext.Provider value={isEditor}>
            <ModeContext.Provider
              value={{
                mode: currentMode,
              }}
            >
              {children}
              <Footer
                language={currentLanguage}
                footerItems={
                  navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].footerItems
                }
                footerSocialMediaIconsText={
                  navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].socialMediaText
                }
                footerSocialMediaItems={
                  navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].footerSocialMedia
                }
              >
                {navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].footerText && (
                    <Markdown>
                      {navigation[currentLanguage].footerText}
                    </Markdown>
                  )}
              </Footer>
              <StickyContactButton
                currentLanguage={currentLanguage}
                currentMode={currentMode}
                contactDetails={contactDetails}
                emailText={
                  navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].stickyButtonEmailText
                }
                phoneText={
                  navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].stickyButtonPhoneText
                }
                careerStickyButtonText={
                  navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].careerStickyButtonText
                }
                careerStickyButtonLink={
                  navigation &&
                  navigation[currentLanguage] &&
                  navigation[currentLanguage].careerStickyButtonLink
                }
              />
            </ModeContext.Provider>
          </EditorContext.Provider>
        </main>
        <NavigationProvider>
          <NavigationContainer
            theme={theme}
            items={navigation}
            language={currentLanguage}
            languages={allLanguage}
            currentURL={currentURL}
            location={location}
          />
        </NavigationProvider>
      </div>
      <SearchModalConsumer
        optionText="In diesem Bereich suchen:"
        buttonText={currentLanguage === "en" ? "Search" : "Suchen"}
        placeholder={currentLanguage === "en" ? "Search" : "Suche"}
        refineLanguage={[currentLanguage]}
        refineMode={
          currentMode === "default" ||
          currentMode === "group" ||
          currentMode === "contact"
            ? [
                "group",
                "food",
                "environment",
                "pharma",
                "wasser",
                "construction",
                "cosmetics",
                "veterinary",
                "medical",
                "sustainabilityServices",
              ]
            : [currentMode]
        }
        appId={algoliaApplicationId}
        searchKey={algoliaSearchApiKey}
        indexName={algoliaIndex}
      />
    </BaseStyles>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  logos: PropTypes.object.isRequired,
  favicon: PropTypes.string.isRequired,
  appleFavicon: PropTypes.string.isRequired,
  currentMode: PropTypes.oneOf([
    "default",
    "group",
    "pharma",
    "food",
    "environment",
    "contact",
    "career",
    "wasser",
    "construction",
    "abf",
    "lkf",
    "sustainabilityServices",
  ]).isRequired,
  navigation: PropTypes.object.isRequired,
  currentURL: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string,
  title: PropTypes.string,
  contactDetails: PropTypes.object,
  canonicalLink: PropTypes.string,
  imageURL: PropTypes.string,
  description: PropTypes.string,
  noAnalytics: PropTypes.bool,
  isEditor: PropTypes.bool,
  location: PropTypes.object,
}

Layout.defaultProps = {
  currentMode: "default",
  navigation: {},
  currentLanguage: "de",
  noAnalytics: false,
}

export default Layout
